import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import React, { Fragment } from 'react';
import Container from '../../components/layout/Container/Container';
import Group from '../../components/layout/Group/Group';
import PageLayout from '../../components/layout/PageLayout/PageLayout';
import PageSection from '../../components/layout/PageSection/PageSection';
import PostCard from '../../components/ui/Cards/PostCard/PostCard';
import Divider from '../../components/ui/Divider/Divider';
import PageBanner from '../../components/ui/PageBanners/PageBanner';
import { PostsSelector } from '../../components/utils/PostsSelector';
import Seo from '../../components/utils/Seo';
import { useAnimationOnScroll } from '../../components/utils/useAnimateOnScroll';
import { Direction } from '../../globals';
import './learn-ai.scss';

interface SoftwareProps {
    data: {
        softwareYaml: {
            image: FluidImage;
            categories: PostsPerCategory[];
        };
        allMdx: {
            nodes: PostDetails[];
        };
    };
}

const Software: React.FC<SoftwareProps> = ({ data }: SoftwareProps) => {
    const { posts, postsPerCategory } = new PostsSelector(
        data.allMdx.nodes
    ).getByCategory(data.softwareYaml.categories);
    useAnimationOnScroll();

    return (
        <PageLayout>
            <Seo
                isPost={false}
                title="Software"
                url="learn-ai/software/"
                description="Learn about software enginnering pracices related to Machine Learning"
            />
            <PageBanner className="page-banner-withcircularimage">
                <Fragment>
                    <h1>AI Software Enginnering</h1>
                    <h3>
                        Focus on Deep Learning from a Software perspective and
                        learn how to build, deploy and scale real-life
                        applications
                    </h3>
                </Fragment>
                <Fragment>
                    <Img
                        fluid={data.softwareYaml.image.childImageSharp.fluid}
                        alt="AI Software Enginnering"
                    ></Img>
                </Fragment>
            </PageBanner>
            {postsPerCategory.map((category) => {
                return (
                    <Fragment key={category.title}>
                        <PageSection>
                            <Container>
                                <Group size={3}>
                                    <div>
                                        <h3>{category.title}</h3>
                                        <p>{category.description}</p>
                                        <Link
                                            className="see-more-link"
                                            to={`/topics/${category.url}`}
                                        >
                                            See more
                                        </Link>
                                    </div>

                                    <PostCard post={posts[category.posts[0]]} />
                                    <PostCard post={posts[category.posts[1]]} />
                                </Group>
                            </Container>
                        </PageSection>
                        <Divider direction={Direction.horizontal} />
                    </Fragment>
                );
            })}
            ;
        </PageLayout>
    );
};

export const fundamentalsQuery = graphql`
    {
        softwareYaml {
            image {
                childImageSharp {
                    fluid {
                        aspectRatio
                        src
                        srcSet
                        sizes
                    }
                }
            }
            categories {
                description
                posts
                title
                url
            }
        }
        allMdx(filter: { frontmatter: { layout: { eq: "BlogPage" } } }) {
            nodes {
                frontmatter {
                    author
                    description
                    image {
                        childImageSharp {
                            fluid(maxWidth: 800) {
                                base64
                                aspectRatio
                                src
                                srcSet
                                sizes
                            }
                        }
                    }
                    layout
                    suburl
                    tags
                    title
                }
            }
        }
    }
`;

export default Software;
